import {
  CHECKBOX_FIELD_TYPE,
  DROPDOWN_FIELD_TYPE,
  INPUT_FIELD_TYPE,
  RADIO_FIELD_TYPE,
  TEXTAREA_FIELD_TYPE
} from '@/utility/formHelpers';

import Checkbox from '../Checkbox';
import FloatingLabelInput from '../FloatingLabelInput';
import Input from '../Input';
import PropTypes from 'prop-types';
import RadioSelect from '../RadioSelect';
import Select from '../Select';
import Textarea from '../Textarea';

const FormField = (props) => {
  const {
    fieldType,
    name,
    label,
    placeholder,
    value,
    error,
    onChange,
    optionsList,
    handleChangeDirectly,
    dataTestId,
    customClassInput,
    customInnerClassnames,
    customInnerInputClassnames,
    customInnerStyle,
    differentDisplay,
    ...rest
  } = props;
  const className = 'c-FormField';

  const renderField = () => {
    switch (fieldType) {
      case INPUT_FIELD_TYPE: {
        if (rest?.isFloating) {
          return (
            <FloatingLabelInput
              name={name}
              label={label}
              placeholder={placeholder}
              onChange={onChange}
              data-testid={`${dataTestId}-input-${name}`}
              error={error}
              {...rest}
            />
          );
        }
        return (
          <Input
            name={name}
            label={label}
            placeholder={placeholder}
            value={value}
            error={error}
            onChange={onChange}
            dataTestId={dataTestId}
            customClassnames={customInnerClassnames}
            customInnerStyle={customInnerStyle}
            customInnerClassName={customInnerInputClassnames}
            {...rest}
          />
        );
      }
      case DROPDOWN_FIELD_TYPE: {
        return (
          <Select
            name={name}
            label={label}
            placeholder={placeholder}
            value={value}
            error={error}
            optionsList={optionsList}
            onChange={onChange}
            dataTestId={dataTestId}
            customClassName={customInnerClassnames}
            customInnerStyle={customInnerStyle}
            differentDisplay={differentDisplay}
            {...rest}
          />
        );
      }
      case CHECKBOX_FIELD_TYPE: {
        return (
          <Checkbox
            name={name}
            label={label}
            checked={value}
            error={error}
            onChange={onChange}
            dataTestId={dataTestId}
          />
        );
      }
      case TEXTAREA_FIELD_TYPE: {
        return (
          <Textarea
            name={name}
            label={label}
            placeholder={placeholder}
            value={value}
            error={error}
            onChange={onChange}
            dataTestId={dataTestId}
            {...rest}
          />
        );
      }
      case RADIO_FIELD_TYPE: {
        return (
          <RadioSelect
            options={optionsList}
            value={value}
            onChange={onChange}
            name={name}
            handleChangeDirectly={handleChangeDirectly}
            dataTestId={dataTestId}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className={`${className} ${customClassInput}`}>
      {renderField()}
    </div>
  );
};

FormField.defaultProps = {
  placeholder: '',
  optionsList: [],
  error: ''
};

FormField.propTypes = {
  fieldType: PropTypes.oneOf([
    DROPDOWN_FIELD_TYPE,
    INPUT_FIELD_TYPE,
    CHECKBOX_FIELD_TYPE,
    TEXTAREA_FIELD_TYPE
  ]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]).isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func
  ]),
  placeholder: PropTypes.string,
  error: PropTypes.string,
  optionsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  handleChangeDirectly: PropTypes.bool,

  customClassInput: PropTypes.string,
  customInnerClassnames: PropTypes.string,
  customInnerStyle: PropTypes.object
};

export default FormField;
