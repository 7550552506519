import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import style from './RadioSelect.module.scss';

const RadioSelect = ({
  options,
  value,
  onChange,
  name,
  label,
  handleChangeDirectly,
  error,
  dataTestId
}) => {
  const className = 'c-RadioSelect';
  const baseClassNames = classNames(className, style[className]);

  const handleOnChange = (e) => {
    if (handleChangeDirectly) {
      onChange(e);
    } else {
      onChange(e.target.value);
    }
  };

  const renderOptions = () =>
    options.map((option, index) => {
      // We need a unique option input id because multiple of these can be rendered on one page. So htmlFor field label gets confused.
      const dashSeparatedValue = option.value?.replace(' ', '-');
      const optionInputId =
        name && dashSeparatedValue
          ? `${index}-${name}-${dashSeparatedValue}`
          : index;

      return (
        <div
          className={`${className}__input-wrapper ${
            style[`${className}__input-wrapper`]
          } first:mt-8 mb-16`}
          key={index}>
          <input
            type="radio"
            id={optionInputId}
            name={name}
            value={option.value}
            checked={value === option.value}
            onChange={handleOnChange}
            data-testid={`${dataTestId}-radioselect-${name}`}
            className="relative w-20 h-20 transition ease-in-out border-2 rounded-full appearance-none border-npl-neutral-light-7 checked:border-2 checked:bg-npl-base-white focus:outline-none focus:ring-2 focus:ring-npl-neutral-light-6 focus:ring-opacity-50 checked:after:content checked:after:w-8 checked:after:h-8 checked:after:bg-npl-neutral-dark-3 checked:after:rounded-full checked:after:absolute checked:after:top-1/2 checked:after:left-1/2 checked:after:-translate-x-1/2 checked:after:-translate-y-1/2"
          />
          {
            <label
              className={classNames(
                `${className}__radio-label`,
                style[`${className}__radio-label`],
                'text-body-sm text-npl-text-icon-on-light-surface-primary'
              )}
              htmlFor={optionInputId}>
              {option.LabelComponent ? (
                <option.LabelComponent />
              ) : (
                option.label
              )}
            </label>
          }
        </div>
      );
    });

  return (
    <div className={`${baseClassNames} font-poppins`}>
      {label && (
        <label
          className={classNames(
            `${className}__label `,
            style[`${className}__label`]
          )}
          htmlFor={name}>
          {label}
        </label>
      )}
      <div
        className={`${className}__input-content ${
          style[`${className}__input-content`]
        } flex flex-col items-stretch`}>
        {renderOptions()}
      </div>
      {error && (
        <p
          className={`${
            style[`${className}__error`]
          } ${`${className}__error`}`}>
          {error}
        </p>
      )}
    </div>
  );
};

RadioSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      LabelComponent: PropTypes.any
    })
  ),
  onChange: PropTypes.func.isRequired,
  handleChangeDirectly: PropTypes.bool,
  dataTestId: PropTypes.string
};

RadioSelect.defaultProps = {
  name: 'option',
  dataTestId: '',
  handleChangeDirectly: false
};

export default RadioSelect;
