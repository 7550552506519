import classNames from 'classnames';
import React from 'react';

import style from './Checkbox.module.scss';

const Checkbox = ({ label, checked, dataTestId, ...props }) => {
  const className = 'c-Checkbox';
  const baseClassNames = classNames(className, style[className], {
    [`${className}--checked`]: checked,
    [style[`${className}--checked`]]: checked
  });

  const checkmarkClassName = `${className}__checkmark`;
  const checkmarkClassNames = classNames(
    checkmarkClassName,
    style[checkmarkClassName],
    {
      [`${checkmarkClassName}--checked`]: checked,
      [style[`${checkmarkClassName}--checked`]]: checked
    }
  );

  return (
    <label className={baseClassNames}>
      {label}
      <input
        type="checkbox"
        {...props}
        data-testid={`${dataTestId}-checkbox-${label}`}
      />
      <span className={checkmarkClassNames}></span>
      {props.error && (
        <p className={`${className}__error`}>{props.error}</p>
      )}
    </label>
  );
};

Checkbox.defaultProps = {
  dataTestId: ''
};

export default Checkbox;
